import React from "react"
import { PastelButton } from "../common/button"
import { LayoutSection } from "../common/layout"
import Token from "../common/token"
import skate from "../../images/icons/skate.svg"
import cake from "../../images/icons/cake.svg"
import sandwich from "../../images/icons/sandwich.svg"
import skater from "../../images/icons/skater.svg"
import hands from "../../images/icons/hands.svg"
import { navigate } from "gatsby"
import data from "../../data/fields"
import { SimplePaddedCard } from "../common/cards"
const Services = () => {
  const sections = [
    {
      title: "PATINAJE LIBRE",
      description:
        "Disfruta de tu tiempo libre patinando sobre hielo. Incluye alquiler de patines y equipo de seguridad. Tenemos todo para que disfrutes al máximo.",
      link: data.url.reserva,
      internal: true,
      icon: skate,
      buttonTitle: "Reserva ahora",
    },
    {
      title: "CLASES DE PATINAJE",
      description:
        "Sin importar tu nivel, aprende patinaje artístico con profesores certificados por la Federación Peruana de Patinaje sobre Hielo.",
      link: data.url.iceGravityWhatsapp(
        "Hola, quisiera obtener información sobre las clases de patinaje sobre hielo."
      ),
      icon: skater,
      buttonTitle: "Más info",
    },
    {
      title: "CELEBRA TU CUMPLEAÑOS",
      description:
        "Pasa un día inolvidable junto a amigos y familia, con paquetes adaptados a tus necesidades. Tenemos grandes sorpresas para los cumpleañeros.",
      link: data.url.whatsapp("Hola, quisiera más información sobre celebraciones de cumpleaños."),
      icon: cake,
      buttonTitle: "Más info",
    },
    {
      title: "EVENTOS PRIVADOS",
      description:
        "Pedidas de mano, reuniones de integración, campeonatos... Nuestra pista está gustosa de recibirte y ayudarte a tener un evento único.",
      link: data.url.whatsapp("Hola, quisiera información para realizar un evento privado."),
      icon: hands,
      buttonTitle: "Más info",
    },
    // TODO: Enable this later
    // {
    //   title: "CAFETERÍA",
    //   description:
    //     "Un espacio para tomar un descanso o esperar a tus hijos mientras se divierten. No te vayas sin probar nuestras delicias.",
    //   link: "/",
    //   icon: sandwich,
    //   buttonTitle: "Ver carta",
    // },
  ]
  return (
    <LayoutSection>
      <div className="flex w-full justify-around py-20 px-8 gap-4">
        <div className="hidden lg:flex flex-col h-full justify-between">
          <Token
            front={
              <img
                src={skate}
                className="w-full object-contain -rotate-12"
                alt="Clases de patinaje"
              />
            }
          />
          <Token
            front={<img src={cake} className="w-full object-contain -rotate-6" alt="Cumpleaños" />}
          />
          <Token front={<img src={sandwich} className="w-full object-contain" alt="Cafetería" />} />
        </div>
        <div className="flex flex-col max-w-5xl grow gap-24">
          {sections.map((section, i) => (
            <SimplePaddedCard key={i}>
              <div className="flex flex-col gap-6">
                <div className="w-full flex md:hidden justify-center">
                  <Token
                    front={
                      <img
                        src={section.icon}
                        className="w-full object-contain"
                        alt={`Imagen de ${section.title}`}
                      />
                    }
                  />
                </div>
                <div className="font-oswald text-5xl md:text-7xl w-full text-center md:text-start">
                  {section.title}
                </div>
                <div className="flex flex-col items-center md:items-end md:flex-row gap-4 md:gap-16 xl:gap-24">
                  <div className="text-xl">{section.description}</div>
                  <div className="w-48 flex items-end">
                    <PastelButton
                      onClick={() =>
                        section.internal
                          ? navigate(section.link)
                          : typeof window !== undefined &&
                            window.open(section.link, "_blank", "noreferrer")
                      }
                    >
                      <span className="whitespace-nowrap">{section.buttonTitle}</span>
                    </PastelButton>
                  </div>
                </div>
              </div>
            </SimplePaddedCard>
          ))}
        </div>
        <div className="hidden lg:flex flex-col h-full justify-around">
          <Token
            front={
              <img src={skater} className="w-full object-contain rotate-6" alt="Patinaje libre" />
            }
          />
          <Token
            front={
              <img src={hands} className="w-full object-contain rotate-12" alt="Eventos privados" />
            }
          />
        </div>
      </div>
    </LayoutSection>
  )
}

export default Services
