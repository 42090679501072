import React from "react"
import Layout from "../components/common/layout"
import HeadGenerator from "../components/common/seo"
import Services from "../components/services/services"
import data from "../data/fields"
import summaryImage from "../images/summary_image.webp"

const Servicios = () => {
  return (
    <Layout hasBackground>
      <Services></Services>
    </Layout>
  )
}

export default Servicios

export const Head = () => (
  <HeadGenerator
    data={{
      title: "Servicios - Jockey On Ice",
      description:
        "Brindamos toda una gama de experiencias para personas y organizaciones. ¡Anímate a tener una aventura sobre el hielo!",
      url: data.url.nosotros,
      image: {
        srcWebp: summaryImage,
        widh: 1200,
        height: 630,
        altText: data.strings.seo.default.title,
      },
    }}
    largeSummaryImage
  />
)
